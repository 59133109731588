<template>
	<div class="settings-privacy">
		<h2 class="section-title">Политика конфиденциальности</h2>
		<div class="settings-privacy__text">
			<p>
				Современные технологии достигли такого уровня, что сплочённость команды
				профессионалов предоставляет широкие возможности для позиций, занимаемых
				участниками в отношении поставленных задач. Не следует, однако,
				забывать, что высококачественный прототип будущего проекта, в своём
				классическом представлении, допускает внедрение своевременного
				выполнения сверхзадачи.
			</p>
			<p>
				С другой стороны, базовый вектор развития, а также свежий взгляд на
				привычные вещи - безусловно открывает новые горизонты для поэтапного и
				последовательного развития общества. Равным образом, сплочённость
				команды профессионалов не оставляет шанса для поставленных обществом
				задач.
			</p>
			<p>
				Учитывая ключевые сценарии поведения, дальнейшее развитие различных форм
				деятельности не даёт нам иного выбора, кроме определения как
				самодостаточных, так и внешне зависимых концептуальных решений.
				Реплицированные с зарубежных источников, современные исследования будут
				рассмотрены исключительно в разрезе маркетинговых и финансовых
				предпосылок. Современные технологии достигли такого уровня, что
				синтетическое тестирование предполагает независимые способы реализации
				форм воздействия.
			</p>
		</div>
	</div>
</template>

<style lang="sass" scoped>
.settings-privacy
	.section-title
		margin-top: 0
		margin-bottom: 30px
</style>